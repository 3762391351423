import { Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { InformErrorService } from '@actassa/shared';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { isArray } from 'lodash-es';
import { BehaviorSubject, merge, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { LoadTimeSheets } from '../+state/app-state/actions/load-timesheets';
import { LoadTimeSheetsSuccess } from '../+state/app-state/actions/load-timesheets-success';
import { PickTimesheet } from '../+state/app-state/actions/pick-timesheet';
import { PickTimesheetHour } from '../+state/app-state/actions/pick-timesheet-hour';
import { TIMESHEET_CONNECTION_ERROR } from '../constants/timesheet.constants';
import { TimesheetExpenseDeleteDto } from '../dto/timesheet-expense-delete.dto';
import { TimesheetExpenseInsertDto } from '../dto/timesheet-expense-insert.dto';
import { TimesheetExpenseUpdateDto } from '../dto/timesheet-expense-update.dto';
import { TimesheetHourDeleteDto } from '../dto/timesheet-hour-delete.dto';
import { TimesheetHourInsertDto } from '../dto/timesheet-hour-insert.dto';
import { TimesheetHourUpdateDto } from '../dto/timesheet-hour-update.dto';
import { TimesheetInsertDto } from '../dto/timesheet-insert.dto';
import { TimesheetHourInterface, TimesheetInterface } from '../interfaces/timesheet.interface';
import { TimesheetHourBreaksDto } from '../types/timesheet-hour-breaks-dto.type';

@Injectable()
export class TimeSheetService {
    public readonly isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly store: Store,
        private readonly informErrorService: InformErrorService,
    ) { }

    public init$(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadTimeSheets),
                    switchMap(({ placementId }: LoadTimeSheets) => this.loadTimesheets$(placementId)),
                ),
        );
    }

    public loadingStart(): void {
        this.isLoading$.next(true);
    }

    public loadingEnd(): void {
        this.isLoading$.next(false);
    }

    public loadTimesheets$(placementId: number): Observable<Array<TimesheetInterface>> {
        this.loadingStart();

        return this.load$(placementId)
            .pipe(
                switchMap((timesheets: Array<TimesheetInterface>) =>
                    this.store.dispatch(new LoadTimeSheetsSuccess(timesheets))
                        .pipe(map(() => timesheets))),
                tap(() => this.loadingEnd()),
                catchError((error: Error) => {
                    this.loadingEnd();
                    this.storeWrapper.showToast(TIMESHEET_CONNECTION_ERROR);

                    this.informErrorService.handleErrorInformRequest({
                        error,
                        message: TIMESHEET_CONNECTION_ERROR,
                        placementId,
                    });

                    return throwError(() => new Error(error.message));
                }),
            );
    }

    public handleTimesheet$(data: TimesheetInsertDto): Observable<unknown> {
        const url = this.buildUrl('v2/timesheet');

        this.loadingStart();

        return this.http.post(url, data)
            .pipe(
                take(1),
                map((response: { data?: Array<unknown>, status: boolean }) => {
                    if (response.status) {
                        return response.data || [];
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheet',
                        response,
                        data,
                    });

                    throw new Error('Server response error.');
                }),
                tap(() => this.loadingEnd()),
                catchError((error) => {
                    this.loadingEnd();

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheet',
                        error,
                        data,
                    });

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    public handleTimesheetHour$(
        data: TimesheetHourInsertDto | TimesheetHourUpdateDto | TimesheetHourDeleteDto,
    ): Observable<unknown> {
        const url = this.buildUrl('v2/timesheets/hour');

        this.storeWrapper.loadingStart();

        return this.http.post(url, data)
            .pipe(
                take(1),
                map((response: { data?: Array<unknown>, status: boolean }) => {
                    if (response.status) {
                        return response.data || [];
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/hour',
                        response,
                        data,
                    });

                    throw new Error('Server response error.');
                }),
                tap(() => this.storeWrapper.loadingEnd()),
                catchError((error) => {
                    this.storeWrapper.loadingEnd();

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/hour',
                        error,
                        data,
                    });

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    public handleTimesheetHourBreak$(data: TimesheetHourBreaksDto): Observable<unknown> {
        const url = this.buildUrl('v2/timesheets/hour/break');

        this.storeWrapper.loadingStart();

        return this.http.post(url, data)
            .pipe(
                take(1),
                map((response: { data?: Array<unknown>, status: boolean }) => {
                    if (response.status) {
                        return response.data || [];
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/hour/break',
                        response,
                        data,
                    });

                    throw new Error('Server response error.');
                }),
                tap(() => this.storeWrapper.loadingEnd()),
                catchError((error) => {
                    this.storeWrapper.loadingEnd();

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/hour/break',
                        error,
                        data,
                    });

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    public handleTimesheetExpense$(
        data: TimesheetExpenseInsertDto | TimesheetExpenseUpdateDto | TimesheetExpenseDeleteDto,
    ): Observable<unknown> {
        const url = this.buildUrl('v2/timesheets/expense');

        this.storeWrapper.loadingStart();

        return this.http.post(url, data)
            .pipe(
                take(1),
                map((response: { data?: Array<unknown>, status: boolean }) => {
                    if (response.status) {
                        return response.data || [];
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/expense',
                        response,
                        data,
                    });

                    throw new Error('Server response error.');
                }),
                tap(() => this.storeWrapper.loadingEnd()),
                catchError((error) => {
                    this.storeWrapper.loadingEnd();

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'POST',
                        requestURL: 'v2/timesheets/expense',
                        error,
                        data,
                    });

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    public getExpenseApprovalStatuses$(): Observable<unknown> {
        const url = this.buildUrl('v2/expense-approval-statuses');

        this.loadingStart();

        return this.http.get(url)
            .pipe(
                take(1),
                map((response: { data?: Array<unknown>, status: boolean }) => {
                    if (response.status) {
                        return response.data || [];
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'GET',
                        requestURL: 'v2/expense-approval-statuses',
                        response,
                    });

                    throw new Error('Server response error.');
                }),
                tap(() => this.loadingEnd()),
                catchError((error) => {
                    this.loadingEnd();

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'GET',
                        requestURL: 'v2/expense-approval-statuses',
                        error,
                    });

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    public updateData$(placementId: number, timesheetId: number): Observable<Array<TimesheetInterface>> {
        this.loadingStart();

        return this.load$(placementId)
            .pipe(
                tap((timesheets: Array<TimesheetInterface>) => this.loadTimesheetsSuccess(timesheets)),
                tap((timesheets: Array<TimesheetInterface>) => {
                    const timesheet = timesheets.find(item => item.TimesheetID === timesheetId);

                    this.pickTimesheet(timesheet);

                    // const timesheetHour = timesheet.timeSheetHours.find(item => item.TIMESHEETHOURID === timesheetHourId);

                    // this.pickTimesheetHour(timesheetHour);
                }),
                tap(() => this.loadingEnd()),
                catchError(() => {
                    this.loadingEnd();

                    return throwError(() => new Error('Server response error.'));
                }),
            );
    }

    private load$(placementId: number): Observable<Array<TimesheetInterface>> {
        const url = this.buildUrl(`v2/timesheet/${placementId}`);

        return this.http.get(url)
            .pipe(
                take(1),
                map((response: { data?: Array<TimesheetInterface>, status: boolean }) => {
                    if (response.status && isArray(response.data)) {
                        return response.data;
                    }

                    this.informErrorService.handleErrorInformRequest({
                        requestType: 'GET',
                        requestURL: 'v2/timesheet/${placementId}',
                        response,
                        placementId,
                    });

                    throw new Error('Server response error.');
                }),
            );
    }

    private buildUrl(slug: string): string {
        return `${this.environment.apiURL}/${slug}`;
    }

    @Dispatch()
    private loadTimesheetsSuccess(timesheets: Array<TimesheetInterface>): LoadTimeSheetsSuccess {
        return new LoadTimeSheetsSuccess(timesheets);
    }

    @Dispatch()
    private pickTimesheet(timesheet: TimesheetInterface): PickTimesheet {
        return new PickTimesheet(timesheet);
    }

    // @Dispatch()
    // private pickTimesheetHour(timesheetHour: TimesheetHourInterface | null): PickTimesheetHour {
    //     return new PickTimesheetHour(timesheetHour);
    // }
}


import { DictionaryInterface, MainMenuItemInterface } from '@actassa/api';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
// import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Browser } from '@capacitor/browser';


@Component({
    selector: 'actassa-main-menu-item',
    templateUrl: './main-menu-item.component.html',
    styleUrls: ['./main-menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuItemComponent implements OnInit {
    @Input() public isDebugMode!: boolean;
    @Input() public menuItem!: MainMenuItemInterface;

    public queryParams: DictionaryInterface<string | number> | null = null;

    public ngOnInit(): void {
        this.updateQueryParams();
    }

    public updateQueryParams(): void {
        // Create idempotent data
        this.queryParams = this.menuItem.queryParams
            ? {
                ...this.menuItem.queryParams,
                hash: (new Date()).valueOf(),
            }
            : null;
    }

    public get hasSubmenu(): boolean {
        return !!this.menuItem.submenu && this.menuItem.submenu.length > 0;
    }

    public get detailIcon(): string {
        return this.menuItem.isOpen ? 'chevron-down' : 'chevron-forward';
    }

    public get isShowMenuItem(): boolean {
        const { isActive, isDebugOnly } = this.menuItem;

        if (isActive) {
            if (this.isDebugMode) {
                return true;
            }

            return !isDebugOnly;
        }

        return false;
    }

    public get labelClass(): Record<string, boolean> {
        return {
            'no-icon': !this.menuItem.icon,
        };
    }

    public get isCustomIcon(): boolean {
        return !!this.menuItem.icon && /assets/.test(this.menuItem.icon);
    }

    public get isExternalLink(): boolean {
        return !!this.menuItem.url && /^https:\/\//.test(this.menuItem.url);
    }

    public toggleMenuItem(menuItem: MainMenuItemInterface): void {
        menuItem.isOpen = !menuItem.isOpen;
    }

    public async openExternalLink(link?: string): Promise<void> {
        if (!link) {
            return;
        }

        // const options: InAppBrowserOptions = {
        //     zoom: 'no',
        // };

        // this.iab.create(link, '_blank', options);
        await Browser.open({ url: link });
    }

    public trackByMenuItem(index: number, menuItem: MainMenuItemInterface): string {
        return menuItem.title;
    }
}

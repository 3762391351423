import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'shared-feature-menu',
    templateUrl: './feature-menu.component.html',
    styleUrls: ['./feature-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureMenuComponent {
    @Input() public disabled = false;
    @Input() public side: 'start' | 'end' = 'end';
    @Input() public swipeGesture = false;
}

import { ApplicationsEnum, InitFunctionType, PlatformEnum, PushHelperType } from '@actassa/api';
import { FcmMessageDataInterface } from '@actassa/api';
import { AppPathsDictionary } from '@actassa/api';
import { Injectable } from '@angular/core';
// import { Messaging } from '@angular/fire/messaging';
import { ActionPerformed, PushNotificationSchema, PushNotifications, RegistrationError, Token } from '@capacitor/push-notifications';
import { Device, DeviceInfo } from '@capacitor/device';
import { AlertController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { FCMPushDataActions } from 'enums/fcm-push-data-actions.enum';
import { isEmpty } from 'lodash-es';
// import { of } from 'rxjs';
// import { catchError, mergeMapTo } from 'rxjs/operators';
import { SetDebugMode } from 'state/root-state/actions/set-debug-mode';
import { SetDeviceToken } from 'state/root-state/actions/set-device-token';
import { SetNotificationData } from 'state/root-state/actions/set-notification-data';

const DEFAULT_APP = ApplicationsEnum.CANDIDATE_TRN;

@Injectable({
    providedIn: 'root',
})
export class PushHandlerService {
    private platform: DeviceInfo['platform'];
    private generatePushMessageHelper: Record<FCMPushDataActions, PushHelperType> = {
        [FCMPushDataActions.SET_DEBUG_MODE]: this.setAppToDebugMode,
    };
    private generatePushInitHelper: Record<PlatformEnum, InitFunctionType> = {
        [PlatformEnum.ANDROID]: this.initNativePushHandler.bind(this),
        [PlatformEnum.IOS]: this.initNativePushHandler.bind(this),
        [PlatformEnum.WEB]: this.initWebPushHandler.bind(this),
    };

    constructor(
        private readonly alertController: AlertController,
        // private readonly angularFireMessaging: Messaging,
    ) { }

    public init(): void {
        Device.getInfo()
            .then(({ platform }: DeviceInfo) => {
                this.platform = platform;

                const pushInitHelper: InitFunctionType = this.generatePushInitHelper[platform];

                if (pushInitHelper) {
                    pushInitHelper();
                }
            });
    }

    private onPushNotificationReceived(notification: PushNotificationSchema): void {
        // alert('Push received: ' + JSON.stringify(notification));

        const { title, body, data, id } = notification;
        const handlerFunction: PushHelperType = this.generatePushMessageHelper[data?.action];

        if (handlerFunction) {
            return handlerFunction.call(this, data);
        }

        const showButton = (isEmpty(data) || !data.action) ? [] :
            [
                {
                    text: 'Show Now',
                    handler: (): void => {
                        this.setNotificationData({
                            ...data,
                            notificationId: id,
                        });

                        if (data.app) {
                            this.gotoAppPage(AppPathsDictionary[data.app]);
                        }

                        return;
                    },
                },
            ];

        this.alertController.create({
            header: title,
            message: body,
            buttons: [
                ...showButton,
                {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
            ],
        })
            .then(alert => alert.present());
    }

    private onRegistrationError(error: RegistrationError): void {
        alert('Error on registration: ' + JSON.stringify(error));
    }

    private onRegistration(deviceToken: Token): void {
        this.setDeviceToken(deviceToken.value, this.platform);
        // if (this.platform === PlatformEnum.ANDROID) {
        // }

        // if (this.platform === PlatformEnum.IOS) {
        //     fcm.getToken()
        //         .then(({ token }) => this.setDeviceToken(token, this.platform))
        //         .catch(err => console.log(err));
        // }
    }

    private onPushNotificationActionPerformed(notification: ActionPerformed): void {
        // alert('Push pushNotificationActionPerformed: ' + JSON.stringify(notification));

        const { data, id } = notification.notification;

        if (data?.app) {
            this.setNotificationData({
                ...data,
                notificationId: id,
            });

            this.gotoAppPage(AppPathsDictionary[data.app]);
        }
    }

    private initWebPushHandler(): void {
        // PushNotifications.requestPermissions()
        //     .pipe(
        //         mergeMapTo(this.angularFireMessaging.tokenChanges),
        //         catchError(error => {
        //             console.error(error);

        //             return of();
        //         }),
        //     )
        //     .subscribe((token: string | null) => {
        //         if (token) {
        //             this.setDeviceToken(token, this.platform);
        //         }
        //     });

        // this.angularFireMessaging.messages
        //     .pipe(
        //         catchError(() => of(null)),
        //     )
        //     .subscribe(payload => {
        //         if (payload) {
        //             const notification = (payload as any).notification as PushNotificationSchema;
        //             const { data } = (payload as any);

        //             this.onPushNotificationReceived({
        //                 ...notification,
        //                 data,
        //             });
        //         }
        //     });
    }

    private async initNativePushHandler(): Promise<void> {
        // PushNotifications.register();
        await PushNotifications.addListener('registration', this.onRegistration.bind(this));
        await PushNotifications.addListener('registrationError', this.onRegistrationError.bind(this));
        await PushNotifications.addListener('pushNotificationReceived', this.onPushNotificationReceived.bind(this));
        await PushNotifications.addListener('pushNotificationActionPerformed', this.onPushNotificationActionPerformed.bind(this));

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }

        await PushNotifications.register();
    }

    @Dispatch()
    private setAppToDebugMode(): SetDebugMode {
        alert('DEBUG mode ON');

        return new SetDebugMode(true);
    }

    @Dispatch()
    private setDeviceToken(token: string, type: DeviceInfo['platform']): SetDeviceToken {
        return new SetDeviceToken(token, type);
    }

    @Dispatch()
    private setNotificationData(payload: FcmMessageDataInterface): SetNotificationData {
        return new SetNotificationData(payload);
    }

    @Dispatch()
    private gotoAppPage(path: string = AppPathsDictionary[DEFAULT_APP]): Navigate {
        return new Navigate([path]);
    }
}

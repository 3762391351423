import {
    Component,
    ChangeDetectionStrategy,
    Attribute,
    Input,
    forwardRef,
    HostBinding,
    ViewChild,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { CameraResultType, CameraSource, Camera, Photo, ImageOptions } from '@capacitor/camera';
import { ActionSheetController, AlertController, isPlatform } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { EMPTY_FUNCTION } from '../../helpers/empty';
import { FilesystemService } from '../../services/filesystem.service';

@Component({
    selector: 'file-input',
    templateUrl: './file-input.component.html',
    styleUrls: ['./file-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent implements ControlValueAccessor {
    @Input() public isDisabled = false;
    @Input() public accept = '*';
    @Input() public label = '';
    @Input() public text: string | null = 'Upload';
    @Input() public acceptFiles: string | null = null;
    @Input() public allowedFileSize = 0;

    @HostBinding('style.opacity')
    private get opacity(): number {
        return this.isDisabled ? 0.25 : 1;
    }

    @ViewChild('inputFile')
    private fileInput!: ElementRef;

    public file!: Array<File>;

    constructor(
        @Attribute('required') public readonly required: boolean,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly alertController: AlertController,
        private readonly androidPermissions: AndroidPermissions,
        private readonly filesystemService: FilesystemService,
        private readonly renderer: Renderer2,
    ) { }

    public onChange = EMPTY_FUNCTION;
    public onTouch = EMPTY_FUNCTION;

    // sets the value used by the ngModel of the element
    public set value(value: Array<File> | File) {
        if (value instanceof File) {
            value = [value];
        }

        if (!value && this.fileInput?.nativeElement) {
            this.renderer.setProperty(this.fileInput.nativeElement, 'value', null);
        }

        this.file = value;
        this.onChange(value);
        this.onTouch(value);
    }
    public get value(): Array<File> {
        return this.file;
    }

    // This will will write the value to the view if the the value changes occur on the model programmatically
    public writeValue(value: File | Array<File>): void {
        this.value = value;
    }

    // When the value in the UI is changed, this method will invoke a callback function
    public registerOnChange(onChange: (value: Array<File>) => void): void {
        this.onChange = (componentValue: Array<File>): void => {
            onChange(componentValue);
        };
    }

    public registerOnTouched(onTouch: () => void): void {
        this.onTouch = onTouch;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public addFiles(): void {
        if (isPlatform('cordova')) {
            this.openActionSheet();
            return;
        }

        this.addPhotoInBrowserApp(this.acceptFiles);
    }

    public async openActionSheet(): Promise<void> {
        const actionSheet = await this.actionSheetCtrl.create({
            cssClass: 'action-sheet',
            buttons: [
                {
                    text: 'Add from Camera',
                    handler: async (): Promise<void> => {
                        await this.filesystemService.checkAndRequestPermission(this.androidPermissions.PERMISSION.CAMERA);

                        return this.uploadImage(CameraSource.Camera);
                    },
                },
                {
                    text: 'Add from Gallery',
                    handler: async (): Promise<void> => {
                        await this.filesystemService.checkAndRequestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);

                        return this.uploadImage(CameraSource.Photos);
                    },
                },
                {
                    text: 'Choose File',
                    handler: async (): Promise<boolean> => {
                        await this.filesystemService.checkAndRequestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
                        this.addPhotoInBrowserApp();

                        return true;
                    },
                },
            ],
        });

        await actionSheet.present();
    }

    private async showAlert(message: string): Promise<void> {
        const alert = await this.alertController.create({
            header: 'Input File Controller',
            message,
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
            ],
        });

        await alert.present();
    }

    private addPhotoInBrowserApp(accept: string = ''): void {
        let fileInput: HTMLInputElement | null = this.fileInput.nativeElement;

        if (!fileInput) {
            return;
        }

        fileInput.accept = accept || this.accept;
        fromEvent(fileInput, 'change')
            .pipe(
                take(1),
                tap((event) => {
                    const target = event.target as HTMLInputElement;

                    this.writeValue(target.files as unknown as Array<File>);
                }),
                finalize(() => {
                    // должен быть удален, т.к. счетчик ссылок обнулится
                    fileInput = null;
                }),
            )
            .subscribe();

        fileInput.click();
    }
    private async uploadImage(source: CameraSource): Promise<void> {
        const cameraOptions: ImageOptions = {
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            saveToGallery: true,
            source,
        };
        const originalPhoto: Photo = await Camera.getPhoto(cameraOptions);
        const response = await fetch(originalPhoto.webPath as string);
        const blob = await response.blob();
        const metadata = {
            type: `image/${originalPhoto.format}`,
        };

        this.writeValue([new File([blob], `image.${originalPhoto.format}`, metadata)]);
    }
}

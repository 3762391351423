import { STORE_WRAPPER_TOKEN } from '@actassa/api';
import { MainMenuItemInterface } from '@actassa/api';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { MenuItemsService } from '../../services/menu-items.service';

@Component({
    selector: 'jobs-placements-placements-menu-items',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemsComponent implements OnInit, OnDestroy {

    public isDebugMode: boolean;
    public menuItems$: Observable<Array<MainMenuItemInterface>>;

    private subscriptions = new Subscription();

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper,
        private readonly menuItemsService: MenuItemsService,
    ) { }

    public ngOnInit(): void {
        const debugModeSubscription = this.storeWrapper.isDebugMode$
            .subscribe((isDebugMode: boolean) => this.isDebugMode = isDebugMode);

        this.subscriptions.add(debugModeSubscription);

        this.menuItems$ = this.menuItemsService.get();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public isShowMenuItem(menuItem: MainMenuItemInterface): boolean {
        const { isActive, isDebugOnly } = menuItem;

        if (isActive) {
            if (this.isDebugMode) {
                return true;
            }

            return !isDebugOnly;
        }

        return false;
    }
}
